<template>
  <div id="addTypePayment" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">TIPO DE PAGO</v-row>
      <v-container fluid class="addCategory">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="4" sm="5" class="marginCol">
            <v-row class="mt-4">
              <div class="form-group mb-5">
                <label for="idPayment">Id Tipo de Nómina</label>
                <input
                  type="text"
                  name="idPayment"
                  id="idPayment"
                  placeholder="Id de Tipo de Nómina"
                  class="inputs"
                  autocomplete="false"
                  v-model="idPayment"
                  required
                  maxlength="3"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mb-5">
                <label for="description" class="mr-12">Descripción</label>
                <input
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Descripción"
                  class="inputs ml-10"
                  autocomplete="false"
                  v-model="description"
                  required
                  maxlength="180"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="validacion()">
                Guardar
              </button>
              <router-link to="/payment" id="backPayment">
                <button class="botonAmarilloDerecho">Regresar</button>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Tipo de Pago</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar el tipo de pago?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Tipo de Pago</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Sesion from "@/components/Sesion";
import Loader from "@/components/Loader";
export default {
  components: {
    Sidebar,
    Sesion,
    Loader,
  },
  data() {
    return {
      search: "",
      show: false,
      expiration: false,
      dialog: false,
      idPayment: "",
      description: "",
      errors: [],
      respuesta: "",
      advice: false,
      errorAlmacen: false,
      confirmation: false,
      error: false,
    };
  },
  methods: {
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/otrosPagos",
          {
            ClaveCFDI: this.idPayment,
            TipoOtroPago: this.description,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.idPayment = "";
          this.description = "";
          this.respuesta = "El tipo de pago fue guardado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.idPayment = "";
            this.description = "";
            this.respuesta = "El tipo de pago no fue almacenado";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    validacion: function () {
      this.errors = [];
      if (
        this.idPayment == "" ||
        this.idPayment == " " ||
        this.idPayment == 0
      ) {
        this.errors.push("Se debe especificar el id del tipo de pago.");
      }
      if (this.description == "" || this.description == " ") {
        this.errors.push(
          "Se debe especificar la descripción del tipo de pago."
        );
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    cancel() {
      this.advice = false;
      this.idPayment = "";
      this.description = "";
    },
    aux() {
      this.confirmation = false;
      this.idPayment = "";
      this.description = "";
      this.$router.push("/payment");
    },
  },
  created() {},
};
</script>